/* eslint-disable no-unused-vars */
import React from 'react';
import './reset-password.scss';
import TopNav from '../../components/top-nav';
import { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { validatePassword } from '../../helper/validation';
import authApi from '../../apis/api/auth';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ResetPassword = () => {
  const query = useQuery();
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [message, setMessage] = useState({});

  useEffect(() => {
    if (validatePassword(password)) {
      delete message.password;
      setMessage({ ...message });
    } else {
      message.password =
        'The password must be minimum eight characters, at least one letter and one number.';
      setMessage({ ...message });
    }
  }, [password]);

  useEffect(() => {
    if (password === passwordConfirmation) {
      delete message.passwordConfirmation;
      setMessage({ ...message });
    } else {
      message.passwordConfirmation = 'The password confirmation does not match.';
      setMessage({ ...message });
    }
  }, [passwordConfirmation]);

  const handleResetPassword = async () => {
    const res = await authApi.resetPassword({
      password: password,
      password_confirmation: passwordConfirmation,
      token: query.get('token'),
    });
    if (res.data) {
      history.push('/sign-in');
    } else {
      message.passwordConfirmation = res.message;
      setMessage({ ...message });
    }
  };

  return (
    <div className="wrap-reset-password-page">
      <TopNav isShow={false} handleClickCube={() => {}} />
      <div className="wrap-login-form">
        <div className="login-form col-lg-4 col-md-4 col-xs-6 col-sm-10">
          <h3>Reset Password</h3>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              className="form-control"
              id="password"
            />
            <small className="form-text text-danger">{message.password}</small>
          </div>
          <div className="form-group">
            <label htmlFor="password-confimation">Password Confirmation</label>
            <input
              type="password"
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              className="form-control"
              id="password-confimation"
            />
            <small className="form-text text-danger">{message.passwordConfirmation}</small>
          </div>
          <div className="form-group wrap-btn-action">
            <div className="login">
              <Link to="/sign-in" className="btn-text">
                Sign In
              </Link>
            </div>
            <button
              type="submit"
              onClick={() => handleResetPassword()}
              className="btn-login btn btn-primary"
              disabled={!!Object.keys(message).length}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
