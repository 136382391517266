import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toLocalDate } from "../../helper/date";
import { reqGetListAppointment } from "../../reduxs/cms/action";

const ContentAppointment = () => {
  const [isSortAsc, toggleSortAsc] = useState(true);
  const [search, setSearch] = useState('');
  const appointments = useSelector((state) => state.cms.appointments);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reqGetListAppointment());
  }, []);

  const renderListAppointments = () => {
    return (appointments || []).map((item) => {
      return (
        <tr
          key={item.id}
          className="border-0"
          style={{
            display: "table",
            width: "100%",
            tableLayout: "fixed",
          }}
        >
          <td className="col-2">{item?.firstName}</td>
          <td className="col-2">{item?.lastName}</td>
          <td className="col-2">{item?.email}</td>
          <td className="col-2">{item?.phone}</td>
          <td className="col-2">{toLocalDate(item?.bookedTime)}</td>
        </tr>
      );
    })
  }

  return (
    <div className="wrap-content-appointment page-container">
      <div className="page-header d-flex align-items-center">
        <h1 className="f-title mb-0">APPOINTMENT CMS</h1>
        <div className="input-group ms-auto page-header__input-search w-50">
          <input
            value={search}
            className="form-control"
            type="text"
            placeholder="Search..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <button
          className={`btn shadow-0 ps-xl-5 pe-0 m-0 ms-xl-5 ${!isSortAsc && "sort-desc"}`}
          onClick={() => toggleSortAsc(!isSortAsc)}
        >
          <span className="btn-icon-append ms-3">
            <svg
              width="12"
              height="16"
              viewBox="0 0 12 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.53033 0.46967C6.23744 0.176777 5.76256 0.176777 5.46967 0.46967L0.696699 5.24264C0.403806 5.53553 0.403806 6.01041 0.696699 6.3033C0.989592 6.59619 1.46447 6.59619 1.75736 6.3033L6 2.06066L10.2426 6.3033C10.5355 6.59619 11.0104 6.59619 11.3033 6.3033C11.5962 6.01041 11.5962 5.53553 11.3033 5.24264L6.53033 0.46967ZM6.75 16L6.75 1H5.25L5.25 16H6.75Z"
                fill="#414345"
              />
            </svg>
          </span>
        </button>
      </div>
      <div className="page-body">
        <div className="wrap-appointment-list">
          <div className="table-responsive-custom">
            <table className="table-custom">
              <thead>
                <tr
                  style={{
                    display: "table",
                    width: "100%",
                    tableLayout: "fixed",
                  }}
                >
                  <th className="col-2 header-level">FirstName</th>
                  <th className="col-2">LastName</th>
                  <th className="col-2 header-training">Email</th>
                  <th className="col-2">Phone</th>
                  <th className="col-2">Booked Time</th>
                </tr>
              </thead>
              <tbody
                style={{
                  display: "block",
                  maxHeight: "calc(100vh - 400px)",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {renderListAppointments()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContentAppointment;
