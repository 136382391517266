import * as Types from './type';
import gallery from '../../apis/api/gallery';

const actGetGalleryList = (data) => ({
  type: Types.GET_GALLERY_LIST,
  data,
});

const actSetIsShowVirtualModal = (data) => ({
  type: Types.SET_IS_SHOW_VIRTUAL_MODAL,
  data,
});

export const actSetActiveGallery = (data) => ({
  type: Types.SET_ACTIVE_GALLERY,
  data,
});

export const reqGetGalleryList = () => (dispatch) => {
  return gallery
    .getListGallery()
    .then((data) => {
      dispatch(actGetGalleryList(data));
    })
};

export const reqSetIsShowVirtualModal = (data) => (dispatch) => {
  dispatch(actSetIsShowVirtualModal(data));
};

export const reqSetActiveGallery = (galleryId, data) => (dispatch) => {
  if (!galleryId) return dispatch(actSetActiveGallery(false));

  return gallery
    .getGalleryDetail(galleryId, data)
    .then(res => {
      dispatch(actSetActiveGallery(res));
    });
}
