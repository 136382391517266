import {
  GET_LIST_CUSTOMERS,
  GET_LIST_UNITS,
  SET_EDITABLE_CUSTOMER,
  SET_EDITABLE_CUSTOMER_NOTE,
  SET_EDITABLE_PROPERTY_COLUMN,
  ADD_ONE_CUSTOMER,
  IS_SHOW_UPLOAD_MEDIA_MODAL,
  GET_LIST_MEDIA,
  SET_EDITABLE_MEDIA,
  SET_IS_SHOW_CREATE_GALLERY_MODAL,
  SET_SELECTED_MEDIA,
  GET_LIST_GALLERIES,
  SET_EDITABLE_GALLERY,
  SET_IS_SHOW_DELETE_GALLERY_MODAL,
  GET_LIST_APPOINTMENT
} from './type';

const initialState = {
  customers: [],
  units: [],
  editableCustomer: null,
  editablePropertyColumn: null,
  editableCustomerNote: null,
  isShowUploadMediaModal: false,
  media: [],
  editableMedia: false,
  isShowCreateGalleryModal: false,
  galleries: [],
  selectedMedia: [],
  editableGallery: false,
  isShowDeleteGalleryModal: false,
  appointments: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_LIST_APPOINTMENT:
      return {
        ...state,
        appointments: action?.data?.data
      }
    case SET_IS_SHOW_DELETE_GALLERY_MODAL:
      return {
        ...state,
        isShowDeleteGalleryModal: action?.data
      }
    case SET_EDITABLE_GALLERY:
      return {
        ...state,
        editableGallery: action?.data?.data
      }
    case GET_LIST_GALLERIES:
      return {
        ...state,
        galleries: action?.data?.data,
      }
    case SET_SELECTED_MEDIA:
      return {
        ...state,
        selectedMedia: action?.data,
      }
    case SET_IS_SHOW_CREATE_GALLERY_MODAL:
      return {
        ...state,
        isShowCreateGalleryModal: action?.data
      }
    case SET_EDITABLE_MEDIA:
      return {
        ...state,
        editableMedia: action?.data?.data
      }
    case GET_LIST_MEDIA:
      return {
        ...state,
        media: action.data.data
      }
    case IS_SHOW_UPLOAD_MEDIA_MODAL:
      return {
        ...state,
        isShowUploadMediaModal: action.data
      }
    case GET_LIST_CUSTOMERS:
      return {
        ...state,
        customers: action.data.data
      };
    case GET_LIST_UNITS:
      return {
        ...state,
        units: action.data.data
      };
    case SET_EDITABLE_CUSTOMER:
      return {
        ...state,
        editableCustomer: action.data
      };
    case SET_EDITABLE_CUSTOMER_NOTE:
      return {
        ...state,
        editableCustomerNote: action.data
      };
    case SET_EDITABLE_PROPERTY_COLUMN:
      return {
        ...state,
        editablePropertyColumn: action.data
      };
    case ADD_ONE_CUSTOMER:
      return {
        ...state,
        customers: [...state.customers, action.data]
      }
    default:
      return state;
  }
};
