import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ContentAppointment from "./content-appointment";
import ContentGallery from "./content-gallery";
import ContentImage from "./content-image";

const Content = () => {
  return (
    <div className="wrap-content-page">
      <Tabs
        defaultActiveKey="content-image"
        className="wrap-tabpane mb-3"
      >
        <Tab eventKey="content-image" title="IMAGES">
          <ContentImage />
        </Tab>
        <Tab eventKey="content-panel" title="GALLERY">
          <ContentGallery />
        </Tab>
        <Tab eventKey="content-appointment" title="APPOINTMENT">
          <ContentAppointment />
        </Tab>
      </Tabs>
    </div>
  )
}

export default Content;
