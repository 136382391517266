import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { toast } from "react-toastify";
import appointment from "../../apis/api/appointment";
import Calendar from "./Calendar";
import './index.scss';
import * as regex from '../../constants/regex';
import { useEffect } from "react";
import { reqGetListAppointment } from "../../reduxs/cms/action";
import { useSelector } from "react-redux";

dayjs.extend(customParseFormat);

const BookingAppointment = () => {
  const [bookingDate, setBookingDate] = useState(new Date());
  const [bookingHour, setBookingHour] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [errors, setErrors] = useState({});
  const [usedTime, setUsedTime] = useState([]);
  const dispatch = useDispatch();
  const appointments = useSelector((state) => state.cms.appointments);

  const timeOptions = [
    {
      id: 1,
      value: '10:00',
      label: '10:00 am',
    },
    {
      id: 2,
      value: '11:00',
      label: '11:00 am',
    },
    {
      id: 3,
      value: '12:00',
      label: '12:00 am',
    },
    {
      id: 4,
      value: '13:00',
      label: '1:00 pm',
    },
    {
      id: 5,
      value: '14:00',
      label: '2:00 pm',
    },
    {
      id: 6,
      value: '15:00',
      label: '3:00 pm',
    },
    {
      id: 7,
      value: '16:00',
      label: '4:00 pm',
    },
    {
      id: 8,
      value: '17:00',
      label: '5:00 pm',
    },
    {
      id: 9,
      value: '18:00',
      label: '6:00 pm',
    },
  ];

  useEffect(() => {
    if (firstName || lastName || email || phone || bookingHour) {
      validate();
    }
  }, [firstName, lastName, email, phone, bookingHour]);

  useEffect(() => {
    bookingDate && getListUsedTime();
  }, [bookingDate]);

  useEffect(() => {
    setUsedTime(appointments.map(item => dayjs(item.bookedTime).format('HH:mm')));
  }, [appointments]);

  const getActiveClass = (value) => {
    return bookingHour === value ? 'active' : '';
  }

  const isNotAvailableTime = (value) => {
    return usedTime.includes(value) ? 'disabled' : '';
  }

  const getListUsedTime = async () => {
    dispatch(reqGetListAppointment({
      startTime: bookingDate.setHours(0, 0, 0, 0),
      endTime: bookingDate.setHours(23, 59, 59, 0),
    }));
  }

  const handleCreateAppointment = async () => {
    if (!validate()) return;

    try {
      bookingDate.setHours(bookingHour.split(':')[0]);
      bookingDate.setMinutes(bookingHour.split(':')[1]);
      bookingDate.setSeconds(0, 0);

      const data = {
        firstName,
        lastName,
        email,
        phone,
        bookedTime: bookingDate.toISOString(),
      }

      const res = await appointment.createAppointment(data);
      if (res?.message) throw Error(res.message);
      resetForm();
      toast.success('Created appointment successfully.');
    } catch (error) {
      toast.error(error?.message);
    }
  }

  const validate = () => {
    setErrors({});
    let err = {};

    if (!firstName) {
      err.firstName = 'First Name is required.';
    }

    if (!lastName) {
      err.lastName = 'Last Name is required.';
    }

    if (!email) {
      err.email = 'Email is required.'
    }

    if (!phone) {
      err.phone = 'Phone is required.'
    }

    if (!bookingHour) {
      err.bookingHour = 'Appointment time is required.';
    }

    if (!regex.EMAIL_REGEX.test(email)) {
      err.email = 'Email format is invalid.';
    }

    if (!regex.PHONE_REGEX.test(phone)) {
      err.phone = 'Phone format is invalid.';
    }

    if (Object.keys(err).length) {
      setErrors(err);
    }

    return !Object.keys(err).length;
  }

  const resetForm = () => {
    setErrors({});
    setBookingHour('');
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhone('');
  }

  return (
    <div className="wrap-booking-appointment">
      <div className="body-container">
        <div className="header">
          <h3 className="title">Make An Appointment</h3>
          <hr />
        </div>
        <div className="body">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6">
              <div className="wrap-calendar">
                <h3 className="title">SELECT DATE</h3>
                <Calendar selectedDate={bookingDate} setSelectedDate={setBookingDate} />
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6">
              <div className="wrap-form">
                <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6 mb-3">
                    <h3 className="title">FIRST NAME</h3>
                    <input value={firstName} onChange={(e) => setFirstName(e.target.value)} className="form-control" type="text" placeholder="Enter your first name ..." />
                    {errors?.firstName && <small className="text-danger">{errors.firstName}</small>}
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6 mb-3">
                    <h3 className="title">LAST NAME</h3>
                    <input value={lastName} onChange={(e) => setLastName(e.target.value)} className="form-control" type="text" placeholder="Enter your first name ..." />
                    {errors?.lastName && <small className="text-danger">{errors.lastName}</small>}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6 mb-3">
                    <h3 className="title">ENTER EMAIL</h3>
                    <input value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" type="email" placeholder="Enter your email ..." />
                    {errors?.email && <small className="text-danger">{errors.email}</small>}
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6 mb-3">
                    <h3 className="title">PHONE</h3>
                    <input value={phone} onChange={(e) => setPhone(e.target.value)} className="form-control" type="text" placeholder="Enter your phone number ..." />
                    {errors?.phone && <small className="text-danger">{errors.phone}</small>}
                  </div>
                </div>
                <h3 className="title">SELECT TIME (EST)</h3>
                <div className="wrap-times">
                  {
                    timeOptions.map((item) => (
                      <button onClick={() => setBookingHour(item.value)} className={`time-item ${getActiveClass(item.value)}`} key={item.id} disabled={isNotAvailableTime(item.value)}>{item.label}</button>
                    ))
                  }
                </div>
                {errors?.bookingHour && <small className="text-danger">{errors.bookingHour}</small>}
              </div>
            </div>
          </div>
          <div className="row wrap-btn d-flex justify-content-center mt-5">
            <button onClick={handleCreateAppointment} className="btn-make-appointment">MAKE APPOINTMENT</button>
          </div>
        </div>

      </div>
    </div>
  )
}

export default BookingAppointment;
