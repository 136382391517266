import React, { useState } from 'react';
import './login.scss';
import TopNav from '../../components/top-nav';
import authApi from '../../apis/api/auth';
import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import jwtAuth from '../../apis/utils/jwtAuth';
import { validateEmail, validatePassword } from '../../helper/validation';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  //useEffect(() => {
  //  redirectIfAuthenticated();
  //}, []);

  useEffect(() => {
    validateEmail(email)
      ? setErrorMessage('')
      : setErrorMessage('The email must be a valid email address.');
    validatePassword(password)
      ? setErrorMessage('')
      : setErrorMessage(
          'The password must be minimum eight characters, at least one letter and one number.'
        );
  }, [email, password]);

  const handleLogin = async () => {
    const data = {
      email: email,
      password: password,
    };

    const res = await authApi.signIn(data);

    if (res?.data) {
      jwtAuth.setAccessToken(res.data.token);
      history.push('/dashboard');
    } else {
      setErrorMessage('Email not found or password does not match');
    }
  };

  const redirectIfAuthenticated = () => {
    if (jwtAuth.checkToken()) {
      getUserProfile();
    }
  };

  const getUserProfile = async () => {
    try {
      const res = await authApi.getMe();

      if (res?.data) {
        history.push('/');
      }
    } catch (error) {
      return;
    }
  };

  return (
    <div className="wrap-login-page">
      <TopNav isShow={false} handleClickCube={() => {}} />
      <div className="wrap-login-form">
        <div className="login-form col-lg-4 col-md-4 col-xs-6 col-sm-10">
          <h3>Sign in</h3>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
              id="email"
              placeholder="Email"
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              className="form-control"
              id="password"
              aria-describedby="passwordHelp"
              placeholder="Password"
            />
            <small id="passwordHelp" className="form-text text-danger">
              {errorMessage}
            </small>
          </div>
          <div className="form-group wrap-btn-action">
            <div className="forgot-password">
              <Link to="/forgot-password" className="btn-text">
                Forgot password?
              </Link>
            </div>
            <button
              type="submit"
              onClick={() => handleLogin()}
              className="btn-login btn btn-primary"
              disabled={!!errorMessage}
            >
              Sign in
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
