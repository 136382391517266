import React from "react";
import { useEffect } from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getImagePath } from "../../helper/media";
import { reqGetListMedia, reqSetSelectedMedia } from "../../reduxs/cms/action";

const MediaPicker = () => {
  const media = useSelector((state) => state.cms.media);
  const selectedMedia = useSelector((state) => state.cms.selectedMedia);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reqGetListMedia());
  }, []);

  const onSelectMedia = (e) => {
    const isChecked = e.target.checked;
    const value = e.target.value;

    if (isChecked) {
      dispatch(reqSetSelectedMedia([...selectedMedia, value]));
    } else {
      dispatch(reqSetSelectedMedia((selectedMedia || []).filter(i => i !== value)));
    }
  }

  const isChecked = (value) => {
    return selectedMedia.includes(value);
  }

  const renderListMedia = () => {
    return (media || []).map((item, index) => {
      return (
        <ListGroupItem key={index}>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-4">
              <div className="pretty p-default">
                <input checked={isChecked(item.id)} value={item.id} type="checkbox" onChange={(e) => onSelectMedia(e)} />
                <div className="state p-primary">
                  <label>{item.name}</label>
                </div>
              </div>
            </div>
            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-8">
              <img className="img-fluid" src={getImagePath(item.path)} alt="" />
            </div>
          </div>
        </ListGroupItem>
      )
    })
  }

  return (
    <div className="wrap-media-picker">
      <ListGroup>
        {renderListMedia()}
      </ListGroup>
    </div>
  )
}

export default MediaPicker;
