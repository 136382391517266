import ApiClient from '../apiClient';
import { authFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(authFetcher);

const getPageSettings = (data) => client.get(endPoint.PAGE_SETTINGS, data);

export default {
    getPageSettings,
};
