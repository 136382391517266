import ApiClient from '../apiClient';
import { defaultFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(defaultFetcher);

const getUserList = (data) => client.get(endPoint.USER_LIST, data);
const getUserDetail = (id, data) => client.get(endPoint.USER_DETAIL.replace(':id', id), data);

export default {
  getUserList,
  getUserDetail,
};
