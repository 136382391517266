import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import gallery from "../../apis/api/gallery";
import { reqGetListGalleries, reqSetEditableGallery, reqSetIsShowDeleteGalleryModal } from "../../reduxs/cms/action";

const DeleteGalleryModal = () => {
  const isShowDeleteGalleryModal = useSelector((state) => state.cms.isShowDeleteGalleryModal);
  const editableGallery = useSelector((state) => state.cms.editableGallery);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(reqSetIsShowDeleteGalleryModal(false));
  }

  const onDeleteGallery = async () => {
    try {
      await gallery.deleteGallery(editableGallery.id);
      dispatch(reqSetIsShowDeleteGalleryModal(false));
      dispatch(reqSetEditableGallery(false));
      dispatch(reqGetListGalleries());
      toast.success('Deleted gallery successfully.')
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <Modal
      size="lg"
      className="wrap-delete-gallery-modal"
      show={isShowDeleteGalleryModal}
      onHide={handleClose}
      centered
    >
      <Modal.Body>
        <div className="modal-form__title mb-4 text-center">
          WOULD YOU LIKE TO DELETE THIS GALLERY?
        </div>
        <div className="wrap-button d-flex justify-content-between">
          <button onClick={handleClose}>CANCEL</button>
          <button className="text-danger" onClick={onDeleteGallery}>DELETE</button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteGalleryModal;
