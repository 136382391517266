/* eslint-disable no-unused-vars */
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import customerApi from '../../apis/api/customer';
import { toLocalDate } from '../../helper/date';

const CustomerDetail = () => {
  const { customerId } = useParams();
  const [customer, setCustomer] = useState();

  useEffect(() => {
    getCustomerDetail(customerId);
  }, []);

  const getCustomerDetail = async (id) => {
    const res = await customerApi.getCustomerDetail(id);
    setCustomer(res.data);
  };

  return (
    <div className="card wrap-customer-detail">
      <div className="card-header d-flex">
        <div className="flex-grow-1">
          <Link to="/dashboard/customers" className="btn-text">
            BACK
          </Link>
        </div>
        <div className="flex-grow-0">
          <small className="d-block">Created Date: {toLocalDate(customer?.createdAt)}</small>
          <small className="d-block">Modified Date: {toLocalDate(customer?.updatedAt)}</small>
          <small className="d-block">Last Login: 07/29/2021</small>
          <small className="d-block">Created By: Admin</small>
        </div>
      </div>
      <div className="card-body row">
        <div className="col-lg-5 col-md-5 col-xs-5 col-sm-12">
          <div className="mb-3">
            <h5 className="text-uppercase">
              {customer?.firstname} {customer?.surname}{' '}
              <Link to={`/dashboard/customers/${customer?.id}/edit`} className="btn-text">
                Edit
              </Link>
            </h5>
          </div>
          <div className="role mb-5">
            <span className="badge rounded-pill bg-dark mb-2 ms-2">{customer?.agent?.name}</span>
          </div>
          <div className="phone mb-3">
            <label className="form-label">Phone</label>
            <h6>{customer?.mobile}</h6>
          </div>
          <div className="email mb-3">
            <label className="form-label">Email</label>
            <h6>{customer?.email}</h6>
          </div>
          <div className="customer-note mb-3">
            <label htmlFor="note" className="form-label">
              Note
            </label>
            <textarea className="form-control" name="note" id="note" cols="30" rows="5">
              {customer?.note}
            </textarea>
          </div>
          <div className="btn-action mb-3">
            <button className="btn btn-sm btn-light">Cancel</button>
            <button className="btn btn-sm btn-primary">Save</button>
          </div>
        </div>
        <div className="col-lg-7 col-md-7 col-xs-7 col-sm-12">
          <h5 className="mb-5">SESSIONS</h5>
          <div className="btn-session-action mb-5">
            <a className="btn-text d-block mb-3" href="#">
              START SESSION
            </a>
            <a className="btn-text d-block mb-3" href="#">
              EMAIL SESSION URL TO CUSTOMER
            </a>
            <Link className="btn-text d-block mb-3" to={`/public/${customerId}`}>
              SESSION URL
            </Link>
            <a className="btn-text d-block mb-3" href="#">
              GENERATE SESSION BROCHURE PDF
            </a>
          </div>
          <h5 className="mb-5">PREFERENCES</h5>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetail;
