import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import mediaApi from "../../apis/api/media";
import { reqGetListMedia, reqSetIsShowUploadMediaModal } from "../../reduxs/cms/action";
import './index.scss';

const UploadMediaModal = () => {
  const isShowUploadMediaModal = useSelector((state) => state.cms.isShowUploadMediaModal);
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [file, setFile] = useState();
  const fileRef = useRef();

  const handleClose = () => {
    dispatch(reqSetIsShowUploadMediaModal(false));
    resetForm();
  }

  const resetForm = () => {
    setName();
    setFile();
    fileRef.current.value = "";
  }

  const onOpenFileInput = () => {
    fileRef.current.click();
  }

  const renderImagePreview = () => {
    if (file) {
      return URL.createObjectURL(file);
    }

    return '/images/image.png';
  }

  const handleUploadMedia = async () => {
    try {
      const formData = new FormData();
      formData.append('type', 'image');
      formData.append('name', name);
      formData.append('file', file);

      await mediaApi.uploadMedia(formData);

      dispatch(reqSetIsShowUploadMediaModal(false));
      toast.success('Uploaded successfully!');
      dispatch(reqGetListMedia());
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <Modal
      size="lg"
      className="wrap-upload-media-modal"
      show={isShowUploadMediaModal}
      onHide={handleClose}
      centered
    >
      <Modal.Body className="wrap-modal-body">
        <div className="modal-form__title mb-4">
          ADD NEW IMAGE
        </div>
        <div className="content">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6">
              <div className="title">UPLOAD IMAGE</div>
              <img onClick={onOpenFileInput} className="img-fluid cursor-pointer" src={renderImagePreview()} alt="" />
              <input ref={fileRef} accept="image/*" id="file" className="hidden" type="file" onChange={(e) => setFile(e.target.files[0])} />
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6">
              <div className="title">IMAGE TITLE</div>
              <input value={name} onChange={(e) => setName(e.target.value)} className="form-control" type="text" />
            </div>
          </div>
        </div>
        <div className="wrap-button d-flex justify-content-between">
          <button className="text-danger" onClick={handleClose}>Cancel</button>
          <button onClick={handleUploadMedia} >Save</button>
        </div>
      </Modal.Body>
    </Modal >
  );
}

export default UploadMediaModal;
