import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import CreateGalleryModal from "../../components/gallery/create-gallery-modal";
import DeleteGalleryModal from "../../components/gallery/delete-gallery-modal";
import EditGalleryModal from "../../components/gallery/edit-gallery-modal";
import { getImagePath } from "../../helper/media";
import { reqGetListGalleries, reqSetEditableGallery, reqSetIsShowCreateGalleryModal } from "../../reduxs/cms/action";

const ContentGallery = () => {
  const dispatch = useDispatch();
  const galleries = useSelector((state) => state.cms.galleries);
  const [isSortAsc, toggleSortAsc] = useState(true);
  const [search, setSearch] = useState('');

  useEffect(() => {
    getListGalleries();
  }, []);


  useEffect(() => {
    getListGalleries();
  }, [search, isSortAsc])

  const getListGalleries = () => {
    dispatch(reqGetListGalleries({
      search,
      sortBy: JSON.stringify({
        name: isSortAsc ? 1 : -1,
      }),
    }));
  }

  const onCreateGallery = () => {
    dispatch(reqSetIsShowCreateGalleryModal(true));
  }

  const onEditGallery = (id) => {
    dispatch(reqSetEditableGallery(id))
  }

  const renderListGalleries = () => {
    if (!galleries?.length) {
      return (
        <div className="d-flex justify-content-center">
          NO GALLERY
        </div>
      )
    }

    return (galleries || [] ).map((item, index) => {
      return (
        <div key={index} className="col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-4 mb-5">
          <div className="wrap-gallery-item">
            <img className="gallery-image" src={getImagePath(item.thumbnail)} />
            <div className="gallery-details">
              <p className="gallery-title">{item?.name}</p>
              <p onClick={() => onEditGallery(item.id)} className="gallery-edit-btn cursor-pointer">EDIT</p>
            </div>
          </div>
        </div>
      )
    })
  }

  return (
    <>
      <div className="wrap-content-gallery page-container">
        <div className="page-header d-flex align-items-center">
          <h1 className="f-title mb-0">GALLERY CMS</h1>
          <div className="input-group ms-auto page-header__input-search w-50">
            <input
              value={search}
              className="form-control"
              type="text"
              placeholder="Search..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <button
            className={`btn shadow-0 ps-xl-5 pe-0 m-0 ms-xl-5 ${!isSortAsc && "sort-desc"}`}
            onClick={() => toggleSortAsc(!isSortAsc)}
          >
            <span className="btn-icon-append ms-3">
              <svg
                width="12"
                height="16"
                viewBox="0 0 12 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.53033 0.46967C6.23744 0.176777 5.76256 0.176777 5.46967 0.46967L0.696699 5.24264C0.403806 5.53553 0.403806 6.01041 0.696699 6.3033C0.989592 6.59619 1.46447 6.59619 1.75736 6.3033L6 2.06066L10.2426 6.3033C10.5355 6.59619 11.0104 6.59619 11.3033 6.3033C11.5962 6.01041 11.5962 5.53553 11.3033 5.24264L6.53033 0.46967ZM6.75 16L6.75 1H5.25L5.25 16H6.75Z"
                  fill="#414345"
                />
              </svg>
            </span>
          </button>
        </div>
        <div className="page-body">
          <div className="wrap-gallery-list">
            <div className="row">
              {renderListGalleries()}
            </div>
          </div>
          <div className="wrap-gallery-actions mt-3 mb-5">
            <div onClick={onCreateGallery} className="btn-add-new-gallery cursor-pointer">ADD NEW GALLERY</div>
          </div>
        </div>
      </div>
      <CreateGalleryModal />
      <EditGalleryModal />
      <DeleteGalleryModal />
    </>
  )
}

export default ContentGallery;
