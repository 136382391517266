import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import customerApi from "../../apis/api/customer";
import { reqGetListCustomers, reqSetEditableCustomerNote } from "../../reduxs/cms/action";

const NotesModal = () => {
  const dispatch = useDispatch();
  const editableCustomerNote = useSelector((state) => state.cms.editableCustomerNote);
  const [note, setNote] = useState('');

  useEffect(() => {
    setNote(editableCustomerNote?.note ? editableCustomerNote.note : 'The note is empty.');
  }, [editableCustomerNote?.note])

  const handleCloseModal = () => {
    dispatch(reqSetEditableCustomerNote());
  }

  const handleUpdateCustomerNote = async () => {
    try {
      if (note === 'The note is empty.') {
        toast.warning("No changes to save.");
        return;
      }
      await customerApi.updateCustomer(editableCustomerNote?.id, {
        note,
      })
      toast.success('Updated customer note successfully');
      handleCloseModal();
      dispatch(reqGetListCustomers());
    } catch (error) {
      toast.error(error?.message);
    }
  }

  return (
    <div
      className={`modal-over-screen ${editableCustomerNote ? "d-block" : "d-none"}`}
    // onClick={() => setIsEditNotes(false)}
    >
      <div
        className="modal-notes shadow-lg"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-header">
          <h1 className="f-title mb-3">NOTES</h1>
        </div>
        <div className="modal-body">
          <textarea onChange={(e) => setNote(e.target.value)} className="w-100 modal-body-text" value={note} rows={5} />
        </div>
        <div className="modal-footer p-0">
          <button
            onClick={handleUpdateCustomerNote}
            className="btn fw-bold shadow-0 pe-0 text-decoration-underline p-0 m-0"
          >
            SAVE
          </button>
        </div>

        <button onClick={handleCloseModal} className="close-btn">
          &times;
        </button>
      </div>
    </div>
  );
};
export default NotesModal;
