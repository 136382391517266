/* eslint-disable no-unused-vars */
import React from 'react';
import { useState, useEffect } from 'react';
import TopNav from '../../components/top-nav';
import './forgot-password.scss';
import authApi from '../../apis/api/auth';
import { Link } from 'react-router-dom';
import { validateEmail } from '../../helper/validation';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    validateEmail(email) ? setMessage('') : setMessage('The email must be a valid email address.');
  }, [email]);

  const handleForgotPassword = async () => {
    const res = await authApi.forgotPassword({
      email: email,
    });
    setMessage('We have emailed your password reset link!');
  };

  return (
    <div className="wrap-forgot-password-page">
      <TopNav isShow={false} handleClickCube={() => {}} />
      <div className="wrap-forgot-pass-form">
        <div className="forgot-pass-form col-lg-4 col-md-4 col-xs-6 col-sm-10">
          <h3>Forgot Pasword</h3>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
              id="email"
            />
            <small className="text-danger">{message}</small>
          </div>
          <div className="form-group wrap-btn-action">
            <div className="forgot-password">
              <Link to="/sign-in" className="btn-text">
                Sign In
              </Link>
            </div>
            <button
              type="submit"
              onClick={() => handleForgotPassword()}
              className="btn-login btn btn-primary"
              disabled={!!message}
            >
              Forgot Password
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
