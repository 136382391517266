/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import customerApi from '../../apis/api/customer';
import userApi from '../../apis/api/user';
import * as yup from 'yup';

const CustomerEdit = (props) => {
  const { notify } = props;
  const { customerId } = useParams();
  const [customer, setCustomer] = useState();
  const [agent, setAgent] = useState();
  const [firstname, setFirstname] = useState();
  const [surname, setSurname] = useState();
  const [mobile, setMobile] = useState();
  const [note, setNote] = useState();
  const [error, setError] = useState();
  const [users, setUsers] = useState([]);
  const history = useHistory();

  useEffect(() => {
    getCustomerDetail(customerId);
    getListUser();
  }, []);

  const getCustomerDetail = async (id) => {
    const res = await customerApi.getCustomerDetail(id);
    setCustomer(res.data);
    setFirstname(res?.data?.firstname);
    setSurname(res?.data?.surname);
    setMobile(res?.data?.mobile);
    setNote(res?.data?.note);
  };

  const getListUser = async () => {
    const res = await userApi.getUserList();
    setUsers(res?.data || []);
  };

  const validattionSchema = yup.object().shape({
    agent: yup.array().of(yup.string()),
    firstname: yup.string().required(),
    surname: yup.string().required(),
    mobile: yup.string().notRequired(),
    note: yup.string().notRequired(),
  });

  const handleEditCustomer = async () => {
    try {
      const data = {
        agent: [agent],
        firstname: firstname,
        surname: surname,
        mobile: mobile,
        note: note,
        password: 'abcd1234',
      };

      const result = await validattionSchema.validate(data);
      const res = await customerApi.updateCustomer(customerId, data);
      notify('Update customer successfully!');
      history.push('/dashboard/customers');
    } catch (err) {
      setError(err);
    }
  };

  return (
    <div className="card wrap-customer-create">
      <div className="card-header d-flex">
        <h6 className="title">EDIT CUSTOMERS</h6>
      </div>
      <div className="card-body">
        <form className="wrap-customer-create">
          <div className="mb-3">
            <label htmlFor="agent" className="form-label">
              Agent
            </label>
            <select
              onChange={(e) => setAgent(e.target.value)}
              className="form-select"
              name="agent"
              id="agent"
            >
              <option value="0">Choose Agent</option>
              {users.map((user, index) => (
                <option key={index} value={user.id}>
                  {user.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="first-name" className="form-label">
              First Name
            </label>
            <input
              onChange={(e) => setFirstname(e.target.value)}
              value={customer?.firstname}
              type="text"
              className="form-control"
              id="first-name"
              aria-describedby="first-name-help"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="surname" className="form-label">
              Surname
            </label>
            <input
              onChange={(e) => setSurname(e.target.value)}
              value={customer?.surname}
              type="text"
              className="form-control"
              id="surname"
              aria-describedby="surname-help"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={customer?.email}
              aria-describedby="email-help"
              disabled
            />
          </div>
          <div className="mb-3">
            <label htmlFor="phone" className="form-label">
              Phone
            </label>
            <input
              onChange={(e) => setMobile(e.target.value)}
              value={customer?.mobile}
              type="text"
              className="form-control"
              id="phone"
              aria-describedby="phone-help"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="note" className="form-label">
              Note
            </label>
            <textarea
              onChange={(e) => setNote(e.target.value)}
              value={customer?.note}
              className="form-control"
              name="note"
              id="note"
              cols="30"
              rows="5"
            />
          </div>
          <div className="mb-3">
            <small id="user-type-help" className="form-text text-danger">
              {error?.message}
            </small>
          </div>
          <div className="mb-3">
            <Link to={`/dashboard/customers`} className="btn bt-light m-0">
              Cancel
            </Link>
            <Link to="#" onClick={() => handleEditCustomer()} className="btn btn-primary">
              Save
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomerEdit;
